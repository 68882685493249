
import {db, doc,collection, getDocs,onSnapshot} from '../../utils/firebase/firebase.utils';


export const getAllProductsThunk = async (id, thunkAPI) => {
  const collRef = collection(db,'product');
  try {
  
    // const data = [];
    // const querySnapshot = await getDocs(docRef);
    // console.log(querySnapshot)
    // querySnapshot.forEach((doc) => {
    //   data.push({ ...doc.data(), id: doc.id });
    // });
    // return data;
    let data = [];
         onSnapshot(collRef, (snapshot) => {
        
            snapshot.docs.map((doc) => (
              data.push({ ...doc.data(), id: doc.id })
            ))
          
        })

        console.log("data", data); // Length should not be 0.
        return data;
   
  } catch (err) {
 
    return thunkAPI.rejectWithValue(err.response.message);
  }
};