
export const PRODUCT_UPLOADING = "Uploading";
export const PRODUCT_DELETED = "Deleted";
export const PRODUCT_PENDING = "Pending";
export const PRODUCT_LIVE = "Live";
export const PRODUCT_REJECTED = "Rejected";
export const PRODUCT_ORDER_PLACED = "Order placed";
export const PRODUCT_DRIVER_PICKUP = "Driver Pickup";
export const PRODUCT_AUTHENTICATING = "Authenticating";
export const PRODUCT_AUTHENTICATING_FAILED = "AuthenticatingFailed";
export const PRODUCT_FAILED = "Failed";
export const PRODUCT_SHIPPED = "Shipped";
export const PRODUCT_DELIVERED = "Delivered";
export const NOTIFICATION_TYPE_FOLLOW = "follow";
export const NOTIFICATION_TYPE_BID_ACCEPTED = "bidAccepted";
export const NOTIFICATION_TYPE_BID_REJECTED = "bidRejected";
export const NOTIFICATION_TYPE_BID_PRICE_FIRST = "bidPrice";
export const NOTIFICATION_TYPE_BID_PRICE_COUNTER = "bidCounterPrice";
export const NOTIFICATION_TYPE_PRICE_DROP = "priceDrop";
export const NOTIFICATION_TYPE_PRODUCT_PRICE_DROP = "productPriceDrop";
export const NOTIFICATION_TYPE_LIKE_PRODUCT = "like";
export const NOTIFICATION_TYPE_COMMENT_PRODUCT = "comment";
export const NOTIFICATION_TYPE_COMMENT_REPLY = "reply";
export const NOTIFICATION_TYPE_ORDER_DELIVERED = "orderDelivered";
export const NOTIFICATION_TYPE_BALANCE_UPDATED = "balanceUpdated";
export const NOTIFICATION_TYPE_BALANCE_ACCEPTED = "balanceAccepted";
export const NOTIFICATION_TYPE_BALANCE_REJECTED = "balanceRejected";
export const NOTIFICATION_TYPE_PRODUCT_ACCEPTED = "productAccepted";
export const NOTIFICATION_TYPE_PRODUCT_REJECTED = "productRejected";
export const NOTIFICATION_TYPE_PRODUCT_ORDER_PLACED = "productOrderPlaced";
export const NOTIFICATION_TYPE_PRODUCT_DRIVER_PICKUP = "productDriverPickup";
export const NOTIFICATION_TYPE_PRODUCT_AUTHENTICATING = "productAuthentication";
export const NOTIFICATION_TYPE_PRODUCT_AUTHENTICATING_FAILED =
  "productAuthenticationFailed";
export const NOTIFICATION_TYPE_PRODUCT_SHIPPED = "productShipped";
export const NOTIFICATION_TYPE_PRODUCT_DELIVERED = "productDelivered";
export const emirates = [
  { id:1,name: "Abu Dhabi", nameAr: "أبو ظبي" },
  {  id:2,name: "Ajman", nameAr: "عجمان" },
  {  id:3,name: "Dubai", nameAr: "دبي" },
  { id:4, name: "Fujairah", nameAr: "الفجيرة" },
  {  id:5,name: "Ras Al Khaimah", nameAr: "رأس الخيمة" },
  {  id:6,name: "Sharjah", nameAr: "الشارقة" },
  {  id:7,name: "Umm Al Quwain", nameAr: "أم القيوين" },
];
