import { initializeApp } from "firebase/app";

import {
  getFirestore,
  onSnapshot,
  collection,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  getDocs,
  query,
  where,
  getDoc,
  setDoc,
  orderBy,
  limit,
  startAfter,
  getCountFromServer,
  FieldPath,
  documentId,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  getStorage,
} from "firebase/storage";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  OAuthProvider,
} from "firebase/auth";
//import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { NOTIFICATION_TYPE_LIKE_PRODUCT } from "../../constants/constants";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
};
//console.log("firebaseConfig",process.env.REACT_APP_FIREBASE_CONFIG_DEV);

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// init services
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
//const messaging = getMessaging(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });
const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");
appleProvider.addScope("name");

const signInWithGoogle = () =>
  signInWithPopup(auth, googleProvider).catch((error) => {
    return { user: { uid: "no_user" } };
  });

// Sign in with apple
const signInWithApple = () =>
  signInWithPopup(auth, appleProvider)
    .then((result) => {
      // Get the OAuthCredential with the access token
      const credential = OAuthProvider.credentialFromResult(result);
      console.log(credential, "credential");
      return { user: result.user };
    })
    .catch((error) => {
      console.error("Apple sign-in error:", error);
      return { user: { uid: "no_user" } };
    });

const checkUsername = async (username) => {
  //console.log("username", username);
  const q = query(
    collection(db, "users"),
    where("username", "==", username.toLowerCase())
  );
};
const checkReferalCode = async (code) => {
  //console.log("username", username);
  const q = query(collection(db, "users"), where("referralCode", "==", code));

  var count = 0;
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    count++;
    //if (count !== 0) return count !== 0;
  });
  return count !== 0;
};

const checkEmail = async (email) => {
  const q = query(
    collection(db, "users"),
    where("email", "==", email.toLowerCase())
  );
  var count = 0;
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    count++;
    if (count !== 0) return count !== 0;
  });
  return count !== 0;
};

const checkPhone = async (phoneNumber) => {
  const q = query(collection(db, "users"), where("phone", "==", phoneNumber));
  var count = 0;
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    count++;
    if (count !== 0) return count !== 0;
  });
  return count !== 0;
};

const createUserDocumentFromAuth = async (userAuth, additionalInfo) => {
  console.log("additionalInfo", additionalInfo);
  const userDocRef = doc(db, "users", userAuth.uid);
  const profileDocRef = doc(db, "profiles", userAuth.uid);
  const cartDocRef = doc(db, "carts", userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);
  const profileSnapshot = await getDoc(profileDocRef);
  const cartSnapshot = await getDoc(cartDocRef);
  //const followingDocRef = collection(db, "following");

  if (!userSnapshot.exists()) {
    const { displayName, email, photoURL } = userAuth;
    const dateCreated = new Date();
    //const photoURL = "";

    try {
      await setDoc(userDocRef, {
        firstName: !displayName ? null : displayName.split(" ")[0],
        lastName: !displayName ? null : displayName.split(" ")[1],
        username: !displayName
          ? null
          : displayName.replace(/\s/g, "").toLowerCase(),
        email,
        dateCreated,
        lastEntered: new Date(),
        photoURL: !photoURL ? "" : photoURL,
        phone: additionalInfo.phone ? additionalInfo.phone : "",
        ...additionalInfo,
      });
    } catch (error) {
      console.log(error.message);
    }
  }
  if (!profileSnapshot.exists()) {
    try {
      const { email } = userAuth;
      await setDoc(profileDocRef, {
        balance: 0,
        bio: "",
        category: "",
        followers: 0,
        followings: 1,
        listing: 0,
        productSold: 0,
        reviews: 0,
      });
      // await follow("PryaWIyD6EQI29kK9GSPNu2CAuD2", userAuth.uid);
      await httpsCallable(
        functions,
        "sendWelcomeEmail"
      )({ sendEmailTo: email });
      await httpsCallable(
        functions,
        "sendEmailVerification"
      )({ email: email, userName: additionalInfo.username });
      // await addDoc(followingDocRef,{
      //   idFollowTo: 'PryaWIyD6EQI29kK9GSPNu2CAuD2',
      //   idFollowFrom : userAuth.uid,
      // }).then(async()=>{
      //   await updateDoc( doc(db, "profiles",'PryaWIyD6EQI29kK9GSPNu2CAuD2'),{
      //     followers: FieldValue.increment(1)
      //   });
      // });
    } catch (error) {
      console.log(error.message);
    }
  }

  if (!cartSnapshot.exists()) {
    try {
      setDoc(cartDocRef, {});
    } catch (error) {}
  }
  return userDocRef;
};

const createUserDocumentFromApple = async (userAuth, additionalInfo) => {
  console.log("additionalInfo", additionalInfo);
  const userDocRef = doc(db, "users", userAuth.uid);
  const profileDocRef = doc(db, "profiles", userAuth.uid);
  const cartDocRef = doc(db, "carts", userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);
  const profileSnapshot = await getDoc(profileDocRef);
  const cartSnapshot = await getDoc(cartDocRef);

  if (!userSnapshot.exists()) {
    const { displayName, email, photoURL } = userAuth;
    const dateCreated = new Date();

    // Initialize firstName and lastName as empty strings by default
    let firstName = "";
    let lastName = "";

    // Try to get firstName and lastName from Apple auth data if available
    // Apple returns user info only on first login through additionalInfo.user
    if (additionalInfo.appleUserData && additionalInfo.appleUserData.name) {
      firstName = additionalInfo.appleUserData.name.firstName || "";
      lastName = additionalInfo.appleUserData.name.lastName || "";
    }
    // If not available from Apple data, try to extract from displayName
    else if (displayName) {
      const nameParts = displayName.split(" ");
      firstName = nameParts.length > 0 ? nameParts[0] : "";
      lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
    }

    try {
      await setDoc(userDocRef, {
        firstName,
        lastName,
        username: additionalInfo.username || "",
        email,
        dateCreated,
        lastEntered: new Date(),
        photoURL: !photoURL ? "" : photoURL,
        phone: additionalInfo.phone ? additionalInfo.phone : "",
        ...additionalInfo,
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  if (!profileSnapshot.exists()) {
    try {
      const { email } = userAuth;
      await setDoc(profileDocRef, {
        balance: 0,
        bio: "",
        category: "",
        followers: 0,
        followings: 1,
        listing: 0,
        productSold: 0,
        reviews: 0,
      });

      await httpsCallable(
        functions,
        "sendWelcomeEmail"
      )({ sendEmailTo: email });
      await httpsCallable(
        functions,
        "sendEmailVerification"
      )({ email: email, userName: additionalInfo.username });
    } catch (error) {
      console.log(error.message);
    }
  }

  if (!cartSnapshot.exists()) {
    try {
      setDoc(cartDocRef, {});
    } catch (error) {}
  }
  return userDocRef;
};

// const verifyPhoneNumber = async (phoneNumber,verify) => {
//   let verify = new RecaptchaVerifier(
//     "submit",
//     {
//       size: "invisible",
//     },
//     auth
//   );
//   var provider = new PhoneAuthProvider();
//   await provider
//     .verifyPhoneNumber("+" + phoneNumber, verify)
//     .then(function (verificationId) {
//       var verificationCode = window.prompt(
//         "Please enter the verification " +
//           "code that was sent to your mobile device."
//       );
//       return auth.PhoneAuthProvider.credential(
//         verificationId,
//         verificationCode
//       );
//     });
// };

const functions = getFunctions();

const addReferral = async (data) => {
  const addReferral = httpsCallable(functions, "addReferral");
  addReferral(data).then((result) => {
    const data = result;
  });
};
const createReferralLink = async (data) => {
  const createReferralLink = httpsCallable(functions, "createReferralLink");
  createReferralLink(data).then((result) => {
    const data = result;
  });
};
const sendQuestion = async (data) => {
  const askQuestion = httpsCallable(functions, "askQuestion");
  askQuestion(data).then((result) => {
    const data = result;
  });
};
const rmPhone = async (uid) => {
  const rmPhoneUser = httpsCallable(functions, "rmPhoneUser");
  rmPhoneUser({ uid: uid }).then((result) => {
    const data = result.data;
    console.log(result);
    console.log(data);
  });
};

const createStripePaymentIntent = async (data) => {
  var paymentIntent = "";
  const createPaymentIntent = httpsCallable(functions, "createPaymentIntent");
  // const createPaymentIntent = httpsCallable(functions, "createTapToken");
  // const createPaymentIntentt = httpsCallable(functions, "createTapAuthorize");
  // await createPaymentIntent().then(async (res)=>  {
  //   console.log(res.data.id)
  //   await createPaymentIntentt({data,tokenId:res.data.id});
  // })
  await createPaymentIntent(data).then((result) => {
    const data = result.data;
    // console.log(result);
    // console.log(data.client_secret);
    // var action = intent.next_action;
    // if (action && action.type === 'redirect_to_url') {
    //   window.location = action.redirect_to_url.url;
    // }else{
    paymentIntent = data.client_secret;
    // }
  });
  return paymentIntent;
};

const follow = async (idFollowTo, idFollowFrom) => {
  const followUser = httpsCallable(functions, "followLibas");
  await followUser({ idFollowTo: idFollowTo, idFollowFrom: idFollowFrom });
  // .then(
  //   (result) => {
  //     const data = result.data;
  //     console.log(result);
  //     console.log(data);
  //   }
  // );
};

const followOther = async (idFollowTo, idFollowFrom, username) => {
  const followUser = httpsCallable(functions, "follow");
  await followUser({
    idFollowTo: idFollowTo,
    idFollowFrom: idFollowFrom,
    username: username,
  });
};

const dhlEstimatedLandingCost = async (
  price,
  thecurrency,
  address,
  countryCode
) => {
  var totalPrice = 0;
  const landingCost = httpsCallable(functions, "estimatedLandingCost");
  await landingCost({
    currency: thecurrency,
    unitPrice: price,
    cityName: address.city,
    addressLine1: address.address,
    postalCode: address.postCode,
    countryCode: countryCode,
  }).then((result) => {
    if (result.data !== "-1") {
      const data = result.data["products"][0]["totalPrice"][0]["price"];
      totalPrice = data;
    } else {
      totalPrice = -1;
    }
  });
  return totalPrice;
};

const signInAuthWithEmailAndPassword = async (identifier, password) => {
  if (!identifier || !password) return;

  let email = identifier;

  // Check if the identifier is an email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(identifier)) {
    // If not an email, treat it as a username
    const q = query(
      collection(db, "users"),
      where("username", "==", identifier.toLowerCase())
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docRef = doc(db, "users", querySnapshot.docs[0].id);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        console.log("No such document!");
        return false;
      } else {
        console.log(docSnap.data().email);
        email = docSnap.data().email;
      }
    } else {
      console.log("No user found with this username!");
      return false;
    }
  }

  // Sign in with the email and password
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error("Error signing in:", error);
    return false;
  }
};
// const signInAuthWithEmailAndPassword = async (username, password) => {
//   if (!username || !password) return;

//   var email = "";
//   const q = query(
//     collection(db, "users"),
//     where("username", "==", username.toLowerCase())
//   );
//   const querySnapshot = await getDocs(q);

//   if (!querySnapshot.empty) {
//     const docRef = doc(db, "users", querySnapshot.docs[0].id);
//     const docSnap = await getDoc(docRef);
//     if (!docSnap.exists()) {
//       console.log("No such document!");
//       return false;
//     } else {
//       console.log(docSnap.data().email);
//       email = docSnap.data().email;
//     }
//     return await signInWithEmailAndPassword(auth, email, password);
//   } else {
//     return false;
//   }
//   //return await signInWithEmailAndPassword(auth,email,password);
// };

const signOutUser = async () => await signOut(auth);

const onAuthStateChangedListener = (callback) => {
  onAuthStateChanged(auth, callback);
};

const createAddress = async (currentUser, address) => {
  const addressRef = collection(db, "profiles", currentUser.uid, "addresses");
  const ref = await addDoc(addressRef, { ...address });
  return ref.id;
};

const updateAddress = async (currentUser, address, docId) => {
  const addressRef = doc(db, "profiles", currentUser.uid, "addresses", docId);
  await updateDoc(addressRef, { ...address });
};

const getAllCoupon = async () => {
  var allCoupon = [];
  const couponRef = collection(db, "coupon");
  const q = query(couponRef);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    allCoupon.push(doc);
  });
  return allCoupon;
};

const getMyBalances = async (id) => {
  const q = doc(db, "balances", id);
  var balance = (await getDoc(q)).data();
  return balance;
};

const getAllMyCoupon = async (uid) => {
  var allCoupon = [];
  const couponRef = collection(db, "profiles", uid, "coupon");
  const q = query(couponRef);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    allCoupon.push(doc.data());
  });
  return allCoupon;
};

const getAddressById = async (uid, aid) => {
  const addrRef = doc(db, "profiles", uid, "addresses", aid);
  const q = query(addrRef);
  const queryResult = await getDoc(q);
  return queryResult.data();
};

const CreateCarts = async (currentUser, productId, withAuth) => {
  try {
    //console.log("withAuth",withAuth);
    const addtocart = doc(db, "carts", currentUser.uid, "cartItems", productId);
    const cartListener = doc(db, "cartListener", currentUser.uid);
    const addcartToProduct = doc(
      db,
      "product",
      productId,
      "carts",
      currentUser.uid
    );
    await setDoc(addtocart, { withAuth, date: new Date() });
    await setDoc(addcartToProduct, {});
    await setDoc(cartListener, {});
  } catch (error) {
    console.log("error", error);
  }
};
const DeleteCarts = async (currentUser, productId, withAuth, mybags) => {
  //console.log("currentUser", currentUser.uid, "productId", productId);
  const cartListener = doc(db, "cartListener", currentUser.uid);
  try {
    const deletecart = doc(
      db,
      "carts",
      currentUser.uid,
      "cartItems",
      productId
    );
    const deletecartfromProduct = doc(
      db,
      "product",
      productId,
      "carts",
      currentUser.uid
    );
    await deleteDoc(deletecart).then(() => {
      console.log(" Document has been deleted successfully.");
    });
    await deleteDoc(deletecartfromProduct).then(() => {
      console.log(" Document2 has been deleted successfully.");
    });
    if (mybags.length == 1) {
      await deleteDoc(cartListener);
    }
  } catch (error) {
    console.log("error", error);
  }
};

const ToogleAuthCarts = async (currentUser, productId, withAuth) => {
  try {
    //console.log("withAuth",withAuth);
    const cartProductRef = doc(
      db,
      "carts",
      currentUser.uid,
      "cartItems",
      productId
    );
    await updateDoc(cartProductRef, { withAuth: withAuth });
  } catch (error) {
    console.log("error", error);
  }
};

const setCouponDataBase = async (name, uid) => {
  var allCoupon = [];
  const proCouponRef = collection(db, "profiles", uid, "coupon");
  const q = query(
    proCouponRef,
    where("name", "==", name),
    orderBy("activatedAt", "desc")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    allCoupon.push(doc.data());
  });
  if (allCoupon.length > 0) {
    const theCoupon = allCoupon[0];
    const newCoupon = {
      ...theCoupon,
      activatedAt: new Date(),
      usableFor: theCoupon.usableFor - 1,
    };
    await addDoc(proCouponRef, newCoupon);
  } else {
    const couponCollectionRef = collection(db, "coupon");
    const q = query(couponCollectionRef, where("name", "==", name));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      allCoupon.push(doc.data());
    });
    const newCoupon = {
      ...allCoupon[0],
      activatedAt: new Date(),
      usableFor: allCoupon[0].usableFor - 1,
    };
    await addDoc(proCouponRef, newCoupon);
  }
};

const checkCoupon = async (name, amount, uid, toCurrency, currencies) => {
  const toCurr = currencies.filter((currency) => currency.id === toCurrency)[0]
    .name;
  var allCoupon = [];
  const couponRef = collection(db, "profiles", uid, "coupon");
  const q = query(
    couponRef,
    where("name", "==", name),
    orderBy("expires", "desc")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    allCoupon.push({ couponId: doc.data().id, ...doc.data() });
  });
  if (allCoupon.length > 0) {
    const coupon = allCoupon[0];
    if (coupon.expires.toDate().getTime() > Date.now()) {
      if (coupon.usableFor > 0) {
        const coup = doc(db, "coupon", coupon.couponId);
        if (!coup.isPaused) {
          if (
            amount >=
            convertGlobalPrice(
              coupon.currencyId,
              coupon.minOrderAmount,
              toCurrency,
              currencies
            )
          ) {
            return { coupon, status: "valid" };
          } else {
            return {
              coupon,
              status: `For orders amounting to ${convertGlobalPrice(
                coupon.currencyId,
                coupon.minOrderAmount,
                toCurrency,
                currencies
              )} ${toCurr} or more.`,
            };
          }
        } else {
          return { coupon, status: "expired" };
        }
      } else {
        return { coupon, status: "already used" };
      }
    } else {
      return { coupon, status: "expired" };
    }
  } else {
    const allCouponRef = collection(db, "coupon");
    const qq = query(
      allCouponRef,
      where("name", "==", name),
      orderBy("expires", "desc")
    );
    const querySnapshotAll = await getDocs(qq);
    querySnapshotAll.forEach((doc) => {
      //console.log(doc.data());
      allCoupon.push({ couponId: doc.id, ...doc.data() });
    });
    if (allCoupon.length > 0) {
      const coupon = allCoupon[0];
      if (coupon.expires.toDate() > Date.now()) {
        if (coupon.usableFor > 0) {
          if (!coupon.isPaused) {
            if (
              amount >=
              convertGlobalPrice(
                coupon.currencyId.length === 0
                  ? "1YiC1Sz9MkYPY3LG8MlN"
                  : coupon.currencyId,
                coupon.minOrderAmount,
                toCurrency,
                currencies
              )
            ) {
              return { coupon, status: "valid" };
            } else {
              return {
                coupon,
                status: `For orders amounting to ${convertGlobalPrice(
                  coupon.currencyId,
                  coupon.minOrderAmount,
                  toCurrency,
                  currencies
                )} ${toCurr} or more.`,
              };
            }
          } else {
            return { coupon, status: "expired" };
          }
        } else {
          return { coupon, status: "already used" };
        }
      } else {
        return { coupon, status: "expired" };
      }
    } else {
      return { status: "not found" };
    }
  }
};

// const convertGlobalPrice = (currencyy, price, toCurrency, currencies) => {
//   //currencyy:id currency
//   //price
//   //toCurrency:id defult or selected
//   // array currencies
//   var priceConverted = price;
//   const curr = currencies.filter((currency) => currency.id === currencyy)[0];
//   const toCurr = currencies.filter((currency) => currency.id === toCurrency)[0];
//   //console.log("currencies", currencies);
//   // console.log(curr);
//   //console.log("toCurr",toCurr);

//   if (curr && toCurr) {
//     if (curr.name === "AED") {
//       if (toCurr.name === "USD") {
//         priceConverted = Math.round(price * curr.toUSD);
//       }
//     } else {
//       if (curr.name === "USD") {
//         if (toCurr.name === "AED") {
//           priceConverted = Math.round(price / toCurr.toUSD);
//         }
//       }
//     }
//   }
//   return Math.round(priceConverted);
// };

// const convertGlobalPrice = (currencyy, price, toCurrency, currencies, code) => {
//   //currencyy:id currency
//   //price
//   //toCurrency:id defult or selected
//   // array currencies
//   var priceConverted = price;
//   const curr = currencies.filter((currency) => currency.id === currencyy)[0];
//   const toCurr = currencies.filter((currency) => currency.id === toCurrency)[0];
//   //console.log("currencies", currencies);
//   // console.log(curr);
//   //console.log("toCurr",toCurr);

//   if (curr && toCurr) {
//     if (curr.name === "AED") {
//       if (toCurr.name === "USD") {
//         priceConverted = Math.round(price * curr.toUSD);
//       }
//       if (toCurr.name === "SAR") {
//         priceConverted = Math.round(price * curr.toSAR);
//       }
//       if (toCurr.name === "EUR") {
//         priceConverted = Math.round(price * curr.toEUR);
//       }
//       if (toCurr.name === "BHD") {
//         priceConverted = Math.round(price * curr.toBHD);
//       }
//       if (toCurr.name === "KWD") {
//         priceConverted = Math.round(price * curr.toKWD);
//       }
//       if (toCurr.name === "OMR") {
//         priceConverted = Math.round(price * curr.toOMR);
//       }
//       if (toCurr.name === "QAR") {
//         priceConverted = Math.round(price * curr.toQAR);
//       }
//     } else {
//       if (curr.name === "USD") {
//         if (toCurr.name === "AED") {
//           priceConverted = Math.round(price * curr.toAED);
//         }
//         if (toCurr.name === "SAR") {
//           priceConverted = Math.round(price * curr.toSAR);
//         }
//         if (toCurr.name === "EUR") {
//           priceConverted = Math.round(price * curr.toEUR);
//         }
//         if (toCurr.name === "BHD") {
//           priceConverted = Math.round(price * curr.toBHD);
//         }
//         if (toCurr.name === "KWD") {
//           priceConverted = Math.round(price * curr.toKWD);
//         }
//         if (toCurr.name === "OMR") {
//           priceConverted = Math.round(price * curr.toOMR);
//         }
//         if (toCurr.name === "QAR") {
//           priceConverted = Math.round(price * curr.toQAR);
//         }
//       }else{
//         if(curr.name === "SAR"){
//           if (toCurr.name === "AED") {
//             priceConverted = Math.round(price * curr.toAED);
//           }
//           if (toCurr.name === "USD") {
//             priceConverted = Math.round(price * curr.toUSD);
//           }
//           if (toCurr.name === "EUR") {
//             priceConverted = Math.round(price * curr.toEUR);
//           }
//           if (toCurr.name === "BHD") {
//             priceConverted = Math.round(price * curr.toBHD);
//           }
//           if (toCurr.name === "KWD") {
//             priceConverted = Math.round(price * curr.toKWD);
//           }
//           if (toCurr.name === "OMR") {
//             priceConverted = Math.round(price * curr.toOMR);
//           }
//           if (toCurr.name === "QAR") {
//             priceConverted = Math.round(price * curr.toQAR);
//           }
//         }else{
//           if(curr.name === "EUR"){
//             if (toCurr.name === "AED") {
//               priceConverted = Math.round(price * curr.toAED);
//             }
//             if (toCurr.name === "USD") {
//               priceConverted = Math.round(price * curr.toUSD);
//             }
//             if (toCurr.name === "SAR") {
//               priceConverted = Math.round(price * curr.toSAR);
//             }
//             if (toCurr.name === "BHD") {
//               priceConverted = Math.round(price * curr.toBHD);
//             }
//             if (toCurr.name === "KWD") {
//               priceConverted = Math.round(price * curr.toKWD);
//             }
//             if (toCurr.name === "OMR") {
//               priceConverted = Math.round(price * curr.toOMR);
//             }
//             if (toCurr.name === "QAR") {
//               priceConverted = Math.round(price * curr.toQAR);
//             }
//           }else{
//             if(curr.name === "EUR"){
//               if (toCurr.name === "AED") {
//                 priceConverted = Math.round(price * curr.toAED);
//               }
//               if (toCurr.name === "USD") {
//                 priceConverted = Math.round(price * curr.toUSD);
//               }
//               if (toCurr.name === "SAR") {
//                 priceConverted = Math.round(price * curr.toSAR);
//               }
//               if (toCurr.name === "BHD") {
//                 priceConverted = Math.round(price * curr.toBHD);
//               }
//               if (toCurr.name === "KWD") {
//                 priceConverted = Math.round(price * curr.toKWD);
//               }
//               if (toCurr.name === "OMR") {
//                 priceConverted = Math.round(price * curr.toOMR);
//               }
//               if (toCurr.name === "QAR") {
//                 priceConverted = Math.round(price * curr.toQAR);
//               }
//             }else{
//               if(curr.name === "EUR"){
//                 if (toCurr.name === "AED") {
//                   priceConverted = Math.round(price * curr.toAED);
//                 }
//                 if (toCurr.name === "USD") {
//                   priceConverted = Math.round(price * curr.toUSD);
//                 }
//                 if (toCurr.name === "SAR") {
//                   priceConverted = Math.round(price * curr.toSAR);
//                 }
//                 if (toCurr.name === "BHD") {
//                   priceConverted = Math.round(price * curr.toBHD);
//                 }
//                 if (toCurr.name === "KWD") {
//                   priceConverted = Math.round(price * curr.toKWD);
//                 }
//                 if (toCurr.name === "OMR") {
//                   priceConverted = Math.round(price * curr.toOMR);
//                 }
//                 if (toCurr.name === "QAR") {
//                   priceConverted = Math.round(price * curr.toQAR);
//                 }
//               }else{
//                 if(curr.name === "BHD"){
//                   if (toCurr.name === "AED") {
//                     priceConverted = Math.round(price * curr.toAED);
//                   }
//                   if (toCurr.name === "USD") {
//                     priceConverted = Math.round(price * curr.toUSD);
//                   }
//                   if (toCurr.name === "SAR") {
//                     priceConverted = Math.round(price * curr.toSAR);
//                   }
//                   if (toCurr.name === "USD") {
//                     priceConverted = Math.round(price * curr.toUSD);
//                   }
//                   if (toCurr.name === "KWD") {
//                     priceConverted = Math.round(price * curr.toKWD);
//                   }
//                   if (toCurr.name === "OMR") {
//                     priceConverted = Math.round(price * curr.toOMR);
//                   }
//                   if (toCurr.name === "QAR") {
//                     priceConverted = Math.round(price * curr.toQAR);
//                   }
//                 }else{
//                   if(curr.name === "KWD"){
//                     if (toCurr.name === "AED") {
//                       priceConverted = Math.round(price * curr.toAED);
//                     }
//                     if (toCurr.name === "USD") {
//                       priceConverted = Math.round(price * curr.toUSD);
//                     }
//                     if (toCurr.name === "SAR") {
//                       priceConverted = Math.round(price * curr.toSAR);
//                     }
//                     if (toCurr.name === "BHD") {
//                       priceConverted = Math.round(price * curr.toBHD);
//                     }
//                     if (toCurr.name === "USD") {
//                       priceConverted = Math.round(price * curr.toUSD);
//                     }
//                     if (toCurr.name === "OMR") {
//                       priceConverted = Math.round(price * curr.toOMR);
//                     }
//                     if (toCurr.name === "QAR") {
//                       priceConverted = Math.round(price * curr.toQAR);
//                     }
//                   }else{
//                     if(curr.name === "OMR"){
//                       if (toCurr.name === "AED") {
//                         priceConverted = Math.round(price * curr.toAED);
//                       }
//                       if (toCurr.name === "USD") {
//                         priceConverted = Math.round(price * curr.toUSD);
//                       }
//                       if (toCurr.name === "SAR") {
//                         priceConverted = Math.round(price * curr.toSAR);
//                       }
//                       if (toCurr.name === "BHD") {
//                         priceConverted = Math.round(price * curr.toBHD);
//                       }
//                       if (toCurr.name === "KWD") {
//                         priceConverted = Math.round(price * curr.toKWD);
//                       }
//                       if (toCurr.name === "OMR") {
//                         priceConverted = Math.round(price * curr.toUSD);
//                       }
//                       if (toCurr.name === "QAR") {
//                         priceConverted = Math.round(price * curr.toQAR);
//                       }
//                     }else{
//                       if(curr.name === "QAR"){
//                         if (toCurr.name === "AED") {
//                           priceConverted = Math.round(price * curr.toAED);
//                         }
//                         if (toCurr.name === "USD") {
//                           priceConverted = Math.round(price * curr.toUSD);
//                         }
//                         if (toCurr.name === "SAR") {
//                           priceConverted = Math.round(price * curr.toSAR);
//                         }
//                         if (toCurr.name === "BHD") {
//                           priceConverted = Math.round(price * curr.toBHD);
//                         }
//                         if (toCurr.name === "KWD") {
//                           priceConverted = Math.round(price * curr.toKWD);
//                         }
//                         if (toCurr.name === "OMR") {
//                           priceConverted = Math.round(price * curr.toOMR);
//                         }
//                         if (toCurr.name === "QAR") {
//                           priceConverted = Math.round(price * curr.toUSD);
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   if(!code){
//     return Math.round(priceConverted)
//   }else{
//     if(code=="AE")
//       {
//         return Math.round(priceConverted)+ Math.round(priceConverted*0.13);
//       }else{
//         return Math.round(priceConverted)+ Math.round(priceConverted*0.14);
//       }
//   }

//   //+(Math.round(priceConverted)*0.13);
// };
const convertGlobalPrice = (currencyy, price, toCurrency, currencies, code) => {
  const curr = currencies.find((currency) => currency.id == currencyy);
  const toCurr = currencies.find((currency) => currency.id == toCurrency);
  const priceThreshold = 20000;
  const priceAuthThreshold = 500;
  if (curr && toCurr) {
    const conversionRates = {
      AED: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      USD: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      SAR: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      EUR: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      BHD: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      KWD: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      OMR: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      QAR: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
    };
    const conversionRate = conversionRates[curr.name][toCurr.name];
    const priceConverted = price * conversionRate;
    const priceThresholdConverted =
      priceThreshold * conversionRates["USD"][toCurr.name];
    const priceAuthThresholdConverted =
      priceAuthThreshold * conversionRates["USD"][toCurr.name];
    const authPrice = Math.round(50 * conversionRates["AED"][toCurr.name]);
    let buyerServiceFee = 0;
    if (!code) {
      return Math.round(priceConverted);
    } else {
      if (code === "AE") {
        if (priceThresholdConverted > priceConverted) {
          return Math.round(priceConverted) + Math.round(priceConverted * 0.13);
        } else {
          return Math.round(priceConverted) + Math.round(priceConverted * 0.03);
        }
      } else {
        if (priceThresholdConverted > priceConverted) {
          return Math.round(priceConverted) + Math.round(priceConverted * 0.14);
        } else {
          return Math.round(priceConverted) + Math.round(priceConverted * 0.04);
        }
      }
    }
    // else {
    //   if (code === "AE") {
    //     if(priceThresholdConverted>priceConverted){
    //       return priceAuthThresholdConverted>priceConverted? Math.round(priceConverted) + Math.round(priceConverted * 0.13):Math.round(priceConverted) + Math.round(priceConverted * 0.13)+authPrice;
    //     }else{
    //       return Math.round(priceConverted) + Math.round(priceConverted * 0.03)+authPrice;
    //     }
    //   } else {
    //     if(priceThresholdConverted>priceConverted){
    //       return priceAuthThresholdConverted>priceConverted?Math.round(priceConverted) + Math.round(priceConverted * 0.14):Math.round(priceConverted) + Math.round(priceConverted * 0.14)+authPrice;
    //     }else{
    //       return Math.round(priceConverted) + Math.round(priceConverted * 0.04)+authPrice;
    //     }
    //   }
    // }
  }
  return price;
};
const convertGlobalPriceBuyerServiceFee = (
  currencyy,
  price,
  toCurrency,
  currencies,
  code
) => {
  const curr = currencies.find((currency) => currency.id == currencyy);
  const toCurr = currencies.find((currency) => currency.id == toCurrency);
  const priceThreshold = 20000;
  const priceAuthThreshold = 500;
  if (curr && toCurr) {
    const conversionRates = {
      AED: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      USD: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      SAR: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      EUR: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      BHD: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      KWD: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      OMR: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
      QAR: {
        AED: curr.toAED,
        USD: curr.toUSD,
        SAR: curr.toSAR,
        EUR: curr.toEUR,
        BHD: curr.toBHD,
        KWD: curr.toKWD,
        OMR: curr.toOMR,
        QAR: curr.toQAR,
      },
    };
    const conversionRate = conversionRates[curr.name][toCurr.name];
    const priceConverted = price * conversionRate;
    const priceThresholdConverted =
      priceThreshold * conversionRates["USD"][toCurr.name];
    const priceAuthThresholdConverted =
      priceAuthThreshold * conversionRates["USD"][toCurr.name];
    const authPrice = Math.round(50 * conversionRates["AED"][toCurr.name]);
    let buyerServiceFee = 0;
    if (!code) {
      return Math.round(priceConverted);
    } else {
      // buyer service fee logic
      if (code === "AE") {
        if (priceThresholdConverted > priceConverted) {
          buyerServiceFee = Math.round(priceConverted * 0.13);
        } else {
          buyerServiceFee = Math.round(priceConverted * 0.03);
        }
      } else {
        if (priceThresholdConverted > priceConverted) {
          buyerServiceFee = Math.round(priceConverted * 0.14);
        } else {
          buyerServiceFee = Math.round(priceConverted * 0.04);
        }
      }
    }
    return {
      originalPrice: Math.round(priceConverted),
      buyerServiceFee: buyerServiceFee,
      totalPrice: Math.round(priceConverted + buyerServiceFee),
    };
  }
  return price;
};

// const convertGlobalPrice = (currencyy, price, toCurrency, currencies) => {
//   var priceConverted = price;
//   const curr = currencies.filter((currency) => currency.id === currencyy)[0];
//   const toCurr = currencies.filter((currency) => currency.id === toCurrency)[0];
//   if (curr && toCurr) {
//     priceConverted = Math.round(price * curr.toUSD/toCurr.toUSD);
//   }
//   return Math.round(priceConverted);
// };

const checkNumberId = async (number) => {
  var isExist = false;
  const q = query(collection(db, "orders"), where("numberId", "==", number));
  const querySnapshot = await getDocs(q);

  //console.log(querySnapshot.empty);
  if (!querySnapshot.empty) {
    isExist = true;
  }

  return isExist;
};

const getNumberId = async () => {
  const numberId = Math.floor(Math.random() * (100000000 + 1));

  const isExistNumberId = await checkNumberId(numberId);

  if (isExistNumberId) {
    return getNumberId();
  } else {
    return numberId;
  }
};

const createOrder = async ({
  currentUser,
  idCurrency,
  amount,
  address,
  isFlash,
  shippingPrice,
  couponId,
  isLibasBlance,
  products,
  bagProducts,
  numberId,
  paymentId,
  isCashOnDelivery,
  status,
  paymentMethod,
}) => {
  // console.log({
  //   idUser,
  //   idCurrency,
  //   amount,
  //   address,
  //   isFlash,
  //   shippingPrice,
  //   coupon,
  //   isLibasBlance,
  //   products,
  //   bagProducts,
  //   numberId,
  //   paymentId,
  // });

  const date = new Date();
  const pricePercentage = address.country === "United Arab Emirates" ? 13 : 14;

  const updatedBagProducts = bagProducts.map((product) => ({
    ...product,
    pricePercentage: pricePercentage,
  }));
  console.log("bagProducts", bagProducts);
  console.log("updatedBagProducts", updatedBagProducts);

  const createTheOrder = httpsCallable(functions, "createOrder");
  const order = currentUser
    ? {
        id: "",
        isDelivered: false,
        numberId: numberId,
        idUser: currentUser.uid,
        date: date,
        amount: amount,
        products: updatedBagProducts,
        idAddress: address.id,
        idCurrency: idCurrency,
        isCashOnDelivery: isCashOnDelivery,
        shippingPrice: shippingPrice,
        isLibasBalance: isLibasBlance,
        couponId: couponId ? couponId.couponId : "",
        paymentId: paymentId,
        status: status,
        country: address.country,
        paymentMethod: paymentMethod,
      }
    : {
        id: "",
        isDelivered: false,
        numberId: numberId,
        idUser: "",
        date: date,
        amount: amount,
        products: updatedBagProducts,
        idAddress: "",
        idCurrency: idCurrency,
        isCashOnDelivery: isCashOnDelivery,
        shippingPrice: shippingPrice,
        isLibasBalance: isLibasBlance,
        couponId: couponId ? couponId.couponId : "",
        paymentId: paymentId,
        status: status,
        country: address.country,
        paymentMethod: paymentMethod,
        contactInfo: address,
      };
  const result = await createTheOrder({
    order,
    products,
    isFlashSale: isFlash,
  });

  if (result.data.created && status != "Pending" && currentUser) {
    await deletAllCart(currentUser.uid);
  }

  console.log(result.data);
  return result.data;
};

const deletAllCart = async (idUser) => {
  try {
    const cartListener = doc(db, "cartListener", idUser);
    console.log(idUser);
    await getDocs(query(collection(db, "carts", idUser, "cartItems"))).then(
      async (snp) => {
        var arrProductId = [];
        snp.forEach((doc) => {
          arrProductId.push(doc.id);
        });
        console.log(arrProductId);
        for (var i = 0; i < arrProductId.length; i++) {
          console.log(arrProductId[i]);
          await deleteDoc(
            doc(db, "carts", idUser, "cartItems", arrProductId[i])
          );
          await deleteDoc(doc(db, "product", arrProductId[i], "carts", idUser));
        }
      }
    );
    await deleteDoc(cartListener);
  } catch (error) {
    console.log("error", error);
  }
};

const getFirebaseToken = async (oldToken, uid) => {
  // try {
  //   var currentToken = await getToken(messaging, {
  //     vapidKey: process.env.REACT_APP_PUSH_NOTIFICATION,
  //   });
  //   if (currentToken != null) {
  //     if (oldToken !== currentToken) {
  //       await updateDoc(doc(db, "users", uid), { webToken: currentToken });
  //     }
  //   }
  // } catch (err) {
  //   console.log("An error occurred while retrieving token. ", err);
  //   // print(error);
  // }
  // .then((currentToken) => {
  //   if (currentToken) {
  //     console.log("current token for client: ", currentToken);
  //     //setTokenFound(true);
  //     // Track the token -> client mapping, by sending to backend server
  //     // show on the UI that permission is secured
  //   } else {
  //     console.log(
  //       "No registration token available. Request permission to generate one."
  //     );
  //     //setTokenFound(false);
  //     // shows on the UI that permission is required
  //   }
  // })
};

const addComment = async (currentUser, comment, productId, user, currentUs) => {
  const commentRef = collection(db, "product", productId, "comments");
  await addDoc(commentRef, {
    userId: currentUser.uid,
    comment: comment,
    date: new Date(),
  });
  if (user.token || user.webToken) {
    const sendCommentNotification = httpsCallable(
      functions,
      "sendCommentNotification"
    );
    sendCommentNotification({
      token: user.token ? user.token : user.webToken,
      sellerId: user.id,
      username: currentUs.username,
    });
  }
};

const deleteComment = async (commentId, productId) => {
  const commentRef = doc(db, "product", productId, "comments", commentId);
  await deleteDoc(commentRef);
};

const addProductReview = async (
  currentUserId,
  sellerId,
  productId,
  review,
  rating,
  isMatch,
  orderId
) => {
  const reviewRef = collection(db, "profiles", sellerId, "reviews");
  const productRef = doc(db, "orders", orderId, "products", productId);
  await addDoc(reviewRef, {
    isMatch: isMatch,
    rating: rating,
    review: review.length === 0 ? "" : review,
    userId: currentUserId,
    date: new Date(),
    productId: productId,
  });
  await updateDoc(productRef, { isReviewed: true });
};

const getProductComments = async (productId) => {};

const sendBillEmail = async (data) => {
  const sendBillEmail = httpsCallable(functions, "sendBillEmailVer2");
  sendBillEmail({
    sendEmailTo: data.user.email,
    date: new Date(),
    total: `${data.subtotal} ${data.currency.name}`,
    full_name: data.user.firstName + " " + data.user.lastName,
    order_number: `${data.numberId}`,
    first_name: `${data.user.firstName[0].toUpperCase()}${data.user.firstName
      .substring(1)
      .toLowerCase()}`,
    shipping_address: data.address.idAddress,
    coupon: data.couponName,
    couponRate: `${data.couponRate} ${data.currency.name}`,
    items: data.items,
    tax: `0 ${data.currency.name}`,
    subtotal: `${data.subtotal} ${data.currency.name}`,
    shipping: `${data.shippingPrice} ${data.currency.name}`,
    payment: data.paymentMethod,
    country: data.address.country,
    address: data.address.address,
    city: data.address.city,
    totalAuthFees: `${data.totalAuthFees} ${data.currency.name}`,
    shippingMethod: data.shippingMethod,
    sellerPrs: data.sellerPrs,
  }).then((result) => {
    const data = result.data;
    console.log(result);
    console.log(data);
  });
};

const setEmailToBeSend = async (data) => {
  const emailRef = doc(db, "emailsToBeSend", data.orderId);
  await setDoc(emailRef, {
    sendEmailTo: data.user.email,
    date: new Date().toISOString(),
    total: `${data.subtotal} ${data.currency.name}`,
    full_name: data.user.firstName + " " + data.user.lastName,
    order_number: `${data.numberId}`,
    first_name: `${data.user.firstName[0].toUpperCase()}${data.user.firstName
      .substring(1)
      .toLowerCase()}`,
    shipping_address: data.address.id,
    coupon: data.couponName,
    couponRate: `${data.couponRate} ${data.currency.name}`,
    items: data.items,
    tax: `0 ${data.currency.name}`,
    subtotal: `${data.subtotal} ${data.currency.name}`,
    shipping: `${data.shippingPrice} ${data.currency.name}`,
    payment: data.paymentMethod,
    country: data.address.country,
    address: data.address.address,
    city: data.address.city,
    totalAuthFees: `${data.totalAuthFees} ${data.currency.name}`,
    shippingMethod: data.shippingMethod,
    sellerPrs: data.sellerPrs,
  });
};

const setNotification = async (userId, type, content) => {
  const notificationRef = collection(db, "notifications");
  try {
    await addDoc(notificationRef, {
      toUid: userId,
      type: type,
      content: content,
      date: new Date(),
      isOpened: false,
    });
  } catch (e) {
    console.log(e);
  }
};

const setNotificationRead = (notId, notifications) => {
  const notificationRef = doc(db, "notifications", notId);
  try {
    const theNotification = notifications.filter(
      (notification) => notification.id === notId
    );
    if (theNotification.length !== 0) {
      if (theNotification[0].isOpened) {
        updateDoc(notificationRef, { isOpened: true });
      }
    }
  } catch (e) {
    //  console.log(e);
  }
};

const deleteNotification = async (userId, type, content) => {
  const notificationRef = collection(db, "notifications");
  const q = query(
    notificationRef,
    where("type", "==", type),
    where("toUid", "==", userId),
    where("content", "==", content)
  );
  try {
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const theNotification = doc(
        db,
        "notifications",
        querySnapshot.docs[0].id
      );
      deleteDoc(theNotification);
    }
  } catch (e) {
    //print(e);
  }
};

// const setToken = async (oldToken, uid, currentToken) => {
//   try {
//     if (currentToken != null) {
//       if (oldToken != currentToken) {
//         await updateDoc(doc("users", uid), { webToken: currentToken });
//       }
//     }
//   } catch (error) {
//     // print(error);
//   }
// };
const handleAddToWishlist = (product, window) => {
  // Event tracking script
  window.gtag("config", "AW-11517267669");
  window.gtag("event", "add_to_wishlist", {
    event_category: "Engagement",
    event_label: "Wishlist",
    value: 1,
    productId: product.id,
  });
};

const toggleLikeProduct = async (
  product,
  uid,
  setIsLiking,
  isLiking,
  window
) => {
  if (product != null && !isLiking && uid) {
    setIsLiking(true);
    const isLiked = product.userLikes.includes(uid);
    var newUserLikes = [];

    if (isLiked) {
      //product.userLikes.remove(uid);
      newUserLikes = product.userLikes.filter((id) => id !== uid);
    } else {
      //product.userLikes.push(uid);
      newUserLikes = [...product.userLikes, uid];
      handleAddToWishlist(product, window);
    }
    const productRef = doc(db, "product", product.id);
    try {
      await updateDoc(productRef, { userLikes: newUserLikes });
    } catch (e) {
      setIsLiking(false);
      //print(e);
    }
    if (isLiked) {
      if (uid !== product.idUser) {
        await deleteNotification(
          product.idUser,
          NOTIFICATION_TYPE_LIKE_PRODUCT,
          `${uid}#${product.id}`
        );
      }
    } else {
      if (uid !== product.idUser) {
        await setNotification(
          product.idUser,
          NOTIFICATION_TYPE_LIKE_PRODUCT,
          `${uid}#${product.id}`
        );
      }
    }
    setIsLiking(false);
  } else {
  }
};

const toggleLikeProductCheckout = async (
  product,
  uid,
  setIsLiking,
  isLiking,
  window,
  updateProductState
) => {
  if (product != null && !isLiking && uid) {
    setIsLiking(true);
    const isLiked = product.userLikes.includes(uid);
    let newUserLikes = isLiked
      ? product.userLikes.filter((id) => id !== uid) // Unlike
      : [...product.userLikes, uid]; // Like

    if (!isLiked) {
      handleAddToWishlist(product, window);
    }

    const productRef = doc(db, "product", product.id);

    try {
      // Update Firestore
      await updateDoc(productRef, { userLikes: newUserLikes });

      // Fetch updated product details
      const docSnap = await getDoc(productRef);
      if (docSnap.exists()) {
        const updatedProduct = { id: product.id, ...docSnap.data() };

        // Update product details in Redux store
        updateProductState(updatedProduct);
      }
    } catch (e) {
      console.error("Error updating likes:", e);
    }

    if (isLiked) {
      if (uid !== product.idUser) {
        await deleteNotification(
          product.idUser,
          NOTIFICATION_TYPE_LIKE_PRODUCT,
          `${uid}#${product.id}`
        );
      }
    } else {
      if (uid !== product.idUser) {
        await setNotification(
          product.idUser,
          NOTIFICATION_TYPE_LIKE_PRODUCT,
          `${uid}#${product.id}`
        );
      }
    }

    setIsLiking(false);
  }
};

const createPostpayCheckout = async (data) => {
  const createCheckOut = httpsCallable(functions, "createCheckOut");
  //console.log(data);
  const resp = await createCheckOut(data);
  return resp.data;
};

const createTabbyCheckOut = async (data) => {
  const createCheckOut = httpsCallable(functions, "createTabbyCheckOutWeb");
  //console.log(data);
  const resp = await createCheckOut(data);
  return resp.data;
};

const deleteOrder = async (orderId) => {
  const orderRef = doc(db, "orders", orderId);
  await updateDoc(orderRef, { status: "Deleted" });
};
const updateReferalinUser = async (userUid, list) => {
  const userRef = doc(db, "users", userUid);
  await updateDoc(userRef, { referralDismiss: list });
};
const getLocation = async () => {
  const getLocationFromIP = httpsCallable(functions, "getLocationFromIP");
  //console.log(data);
  const resp = await getLocationFromIP();
  console.log(resp.data);
  return resp.data;
};

const forgotPassword = async (data) => {
  const sendRestPasswordEmail = httpsCallable(
    functions,
    "sendRestPasswordEmail"
  );
  console.log(data);
  const resp = await sendRestPasswordEmail(data);
  //console.log('loooog',resp.data);
  return resp.data;
};

const addRecentlyViewProduct = async (productId, uid) => {
  try {
    const recentlyViewedRef = doc(
      db,
      "profiles",
      uid,
      "recentlyViewedProducts",
      productId
    );
    await setDoc(recentlyViewedRef, { date: new Date() });
  } catch (e) {}
};

const getUserAddress = async (userId) => {
  try {
    const collRef = collection(db, "profiles", userId, "addresses");
    const q = query(collRef, where("type", "==", "Shipping"));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return { uid: userId, address: "unavailable" };
    } else {
      const add = querySnapshot.docs.filter(
        (doc) => doc.data().isVisible == null || doc.data().isVisible
      );
      console.log(add);
      return { uid: userId, address: add[0].data().city };
    }
  } catch (e) {}
};

const editProfile = async (data) => {
  const editMyProfile = httpsCallable(functions, "editMyProfile");
  await editMyProfile(data);
};

const getUserDataByUid = async (userUid, usersData, currentUid) => {
  const userDoc = doc(db, "users", userUid);
  let userModel = null;
  try {
    const users = usersData.filter((element) => element.uid === userUid);
    if (users.length > 0) {
      return Promise.resolve(users[0]);
    } else {
      let isMe = false;
      if (currentUid != null) {
        const uid = currentUid;
        if (uid === userUid) {
          isMe = true;
        }
      }
      if (isMe) {
        const myStream = onSnapshot(userDoc, (snap) => {
          if (snap.exists) {
            const id = snap.id;
            const user = { ...snap.data(), id };
            const index = usersData.findIndex(
              (element) => element.uid === userUid
            );
            if (user != null) {
              if (index == -1) {
                usersData.push(user);
              } else {
                usersData.splice(index, 1, user);
              }
            }
            userModel = user;
          }
        });
      } else {
        return getDoc(userDoc).then((snap) => {
          if (snap.exists) {
            const id = snap.id;
            const user = { ...snap.data(), id };
            const index = usersData.findIndex(
              (element) => element.uid === userUid
            );
            if (user != null) {
              if (index == -1) {
                usersData.push(user);
              } else {
                usersData.splice(index, 1, user);
              }
            }
            userModel = user;
          }
        });
      }
    }
  } catch (e) {
    // Handle the error.
  }
  return Promise.resolve(userModel);
};

const getUserDataByEmail = async (email) => {
  //console.log("username", username);
  const q = query(
    collection(db, "users"),
    where("email", "==", email.toLowerCase())
  );
  var user;
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    user = doc.data();
    //if (count !== 0) return count !== 0;
  });
  return user;
};

const getUserDataById = async (uid) => {
  const q = doc(db, "users", uid);
  var user = (await getDoc(q)).data();
  // console.log(user, "hiiiiiiiii");
  return { ...user, id: uid };
};
const getBlogProducts = async (id) => {
  const q = doc(db, "product", id);
  var prod = (await getDoc(q)).data();
  return { ...prod, id: id };
};

const getUserDataByCode = async (referralcode) => {
  const q = query(
    collection(db, "users"),
    where("referralCode", "==", referralcode)
  );
  var user;
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    user = doc.id;

    //if (count !== 0) return count !== 0;
  });
  return user;
};

const getOnlineSellerProducts = async (userId, count) => {
  try {
    const sellerProducts = [];
    let q = query(
      collection(db, "product"),
      where("idUser", "==", userId),
      where("status", "==", "Live")
    );

    if (count) {
      q = query(q, limit(count));
    }

    const docs = await getDocs(q);
    docs.forEach((doc) => {
      sellerProducts.push({ id: doc.id, ...doc.data() });
    });

    return sellerProducts;
  } catch (e) {
    if (process.env.REACT_APP_ENV === "development") {
      console.error("getOnlineSellerProducts:", e);
    }
    return [];
  }
};

const getOnlineProductsTopPicks = async (lastDocsOld, count) => {
  const topPicks = [];
  let lastDocsTopPicks = lastDocsOld;
  let counter = 0;
  const map = {};
  const q = query(
    collection(db, "product"),
    where("status", "==", "Live"),
    where("likesCount", "!=", 0),
    orderBy("likesCount", "desc")
  );

  try {
    if (!lastDocsTopPicks) {
      if (count !== 0) {
        const countQuery = query(q);
        const countSnapshot = await getDocs(countQuery);
        counter = countSnapshot.size;
      }

      const initialQuery = query(q, limit(count === 0 ? 20 : count));
      lastDocsTopPicks = await getDocs(initialQuery);
    } else {
      const nextQuery = query(
        q,
        startAfter(lastDocsTopPicks.docs[lastDocsTopPicks.docs.length - 1]),
        limit(count === 0 ? 20 : count)
      );
      lastDocsTopPicks = await getDocs(nextQuery);
    }

    lastDocsTopPicks.forEach((doc) => {
      try {
        const pr = { id: doc.id, ...doc.data() };
        topPicks.push(pr);
      } catch (e) {
        if (process.env.REACT_APP_ENV === "development") {
          console.error(e);
        }
      }
    });

    map.counter = counter;
    map.snap = lastDocsTopPicks;
    map.products = topPicks;
  } catch (e) {
    if (process.env.REACT_APP_ENV === "development") {
      console.error(e);
    }
  }

  return map;
};

export {
  auth,
  db,
  onSnapshot,
  collection,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOutUser,
  updateProfile,
  onAuthStateChanged,
  getDocs,
  getDoc,
  query,
  where,
  storage,
  signInWithGoogle,
  signInWithApple,
  createUserDocumentFromAuth,
  createUserDocumentFromApple,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  rmPhone,
  checkUsername,
  checkReferalCode,
  checkEmail,
  checkPhone,
  signInAuthWithEmailAndPassword,
  onAuthStateChangedListener,
  convertGlobalPriceBuyerServiceFee,
  createAddress,
  updateAddress,
  createStripePaymentIntent,
  checkCoupon,
  CreateCarts,
  convertGlobalPrice,
  DeleteCarts,
  dhlEstimatedLandingCost,
  createOrder,
  getNumberId,
  getFirebaseToken,
  deletAllCart,
  orderBy,
  getCountFromServer,
  sendBillEmail,
  addComment,
  toggleLikeProduct,
  toggleLikeProductCheckout,
  createPostpayCheckout,
  addRecentlyViewProduct,
  forgotPassword,
  getLocation,
  setNotification,
  sendQuestion,
  followOther,
  getUserAddress,
  editProfile,
  setEmailToBeSend,
  createTabbyCheckOut,
  deleteOrder,
  getUserDataByEmail,
  getUserDataById,
  getMyBalances,
  updateReferalinUser,
  addReferral,
  createReferralLink,
  getUserDataByCode,
  getBlogProducts,
  getOnlineSellerProducts,
  getOnlineProductsTopPicks,
  documentId,
  ToogleAuthCarts,
  setCouponDataBase,
};
