import { createSlice } from '@reduxjs/toolkit';
import {initialState} from '../InitialState/user'

const UserModel = createSlice({
  name: 'UserModel',
  initialState,
  reducers: {
    
    handleView: (state) => {
     
    },
},

});



export const { handleView} =
UserModel.actions;
export default UserModel.reducer;
