import React from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UserProvider } from "./context/user.context";
import { store,persistor } from './store';
import { Provider } from 'react-redux';
// import { Elements } from "@stripe/react-stripe-js";
//import { stripePromise  } from "./utils/stripe/stripe.utils";
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import './i18n';
import { PersistGate } from "redux-persist/integration/react";
import { Helmet } from "react-helmet";
import ScrollButton from "./ScrollButton";
import AOS from 'aos';
import 'aos/dist/aos.css';

// Initialize AOS
AOS.init();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
    {/* <Elements stripe={stripePromise}> */}
    
   <ScrollButton/>
    <App />
    

    {/* </Elements> */}
    </PersistGate> 
    </Provider>
    </UserProvider>
);

