import { createSlice } from '@reduxjs/toolkit';
const initialState = {

 mybags:[]
};


const MybagSlice = createSlice({
  name: 'mybag',
  initialState,
  reducers: {

    FillMybags: (state, { payload }) => {
   state.mybags=payload
  },

 

},

});

export const { FillMybags } =
MybagSlice.actions;

export default MybagSlice.reducer;
