export const ProductLocalStorage = (product) => {
  localStorage.setItem("product", JSON.stringify(product));
};

export const IdSubCategoryLocalStorage = (idSubCategory) => {
  localStorage.setItem("idSubCategory", JSON.stringify(idSubCategory));
};
export const ProductDetailsLocalStorage = (details) => {
  localStorage.setItem("details", JSON.stringify(details));
};

  export const CurreLocalStorage = (currency) => {
    localStorage.setItem('currency', JSON.stringify(currency));
  };
  export const LangLocalStorage = (currency) => {
    localStorage.setItem('language', JSON.stringify(currency));
  };
export const SaveAddressLocalStorage = (details) => {
  try {
    localStorage.setItem("address", JSON.stringify(details));
  } catch (error) {
    console.log(error);
  }
};

export const GetAddressLocalStorage = () => {
  try {
    const address = localStorage.getItem("address");
    if (!address) return undefined;
    else return JSON.parse(address);
  } catch (error) {
    return undefined;
  }
};
