import { createSlice } from '@reduxjs/toolkit';



const initialState = {
  topSearches:[],
  topsearchquery:'',
  searchQuary:"",
  type:"",
  subtype:''
};


const TopSearchesSlice = createSlice({
  name: 'topsearches',
  initialState,
  reducers: {
    GetTopSeacrhes: (state, { payload }) => {
      state.topSearches=payload;
   
    },
   
    SetTopSeacrhes: (state, { payload }) => {
      state.topsearchquery=payload;
   
    },
    SetSearch: (state, { payload }) => {
      state.searchQuary=payload;
   
    },
    SetTypeMenu: (state, { payload }) => {
      state.type=payload;
   
    },
    SetTypeMenuSub: (state, { payload }) => {
      state.subtype=payload;
   
    },

},

});

export const {GetTopSeacrhes,SetTopSeacrhes,SetSearch,SetTypeMenu,SetTypeMenuSub} =
TopSearchesSlice.actions;

export default TopSearchesSlice.reducer;