import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  idSubCategory:"",


};


const SubCategoriesSlice = createSlice({
  name: 'allSubCategories',
  initialState,
  reducers: {

  getIdSub: (state, { payload }) => {
   state.idSubCategory=payload
  },

 

},
  extraReducers: {

  },
});

export const { getIdSub } =
SubCategoriesSlice.actions;

export default SubCategoriesSlice.reducer;
