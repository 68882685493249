import { createContext, useState, useEffect } from "react";
import {
  onAuthStateChangedListener,
  onSnapshot,
  doc,
  db,
} from "../utils/firebase/firebase.utils";

export const UserContext = createContext({
  currentUser: null,
  setCurrentUser: () => null,
});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const value = { currentUser, setCurrentUser };
  let userData = {};

  useEffect(() => {
    const unsubcribe = onAuthStateChangedListener((user) => {
      if (user) {
        onSnapshot(doc(db, "users", user.uid), (snap) => {
          userData = snap.data();
          setCurrentUser({ ...user, ...userData });
        });
      } else {
        setCurrentUser(user);
      }
    });
    return unsubcribe;
  }, []);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
