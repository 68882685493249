import { createSlice } from '@reduxjs/toolkit';
import {LangLocalStorage
} from '../../util/localStorage';
const initialState = {
    language:true,

};


const LanguageSilce = createSlice({
  name: 'lang',
  initialState,
  reducers: {

  SetLang : (state, { payload }) => {
   state.language=payload;
   console.log("payload",payload)
   LangLocalStorage(payload);
  },


},

});

export const { SetLang} =
LanguageSilce.actions;

export default LanguageSilce.reducer;
