import { createSlice } from '@reduxjs/toolkit';


import {AddressLocalStorage,
  } from '../../util/localStorage';

const initialState = {
  orders:[],
  orderProduct:[],
  address:{},
  order:{},
  orderId:null,
};



const OrderSlice = createSlice({
  name: 'allOrders',
  initialState,
  reducers: {
    GetAllOrders: (state, { payload }) => {
     
 
      state.orders=payload;
   
    },
    DeleteAllOrders: (state, { payload }) => {
     
 
      state.orders=[];
   
    },
    GetAllOrderProduct: (state, { payload }) => {
     
 
      state.orderProduct.push(payload);
   
    },
    DeleteAllOrderProduct: (state, { payload }) => {
     
 
      state.orderProduct=[];
   
    },
    GetOrder: (state, { payload }) => {
     
 
        state.order=payload;
     
      },
      GetAddress: (state, { payload }) => {
     
        state.address=payload;
        //AddressLocalStorage(payload);
     
      },
      SetOrderId: (state, { payload }) => {
     
 
        state.orderId=payload;
     
      },

},
//   extraReducers: {
//     [getAllddresses.pending]: (state) => {
//       state.isLoading = true;
//     },
//     [getAllddresses.fulfilled]: (state, {payload }) => {
      
//       const address = payload;

//       state.isLoading = false;
//       state.addresses = addresses;
//       OrderLocalStorage(address);
//     },
//     [getAllddresses.rejected]: (state, { payload }) => {
//       state.isLoading = false;
   
//     },


//   },
});

export const { GetAllOrders,GetOrder,GetAddress,DeleteAllOrders,GetAllOrderProduct,DeleteAllOrderProduct,SetOrderId} =
OrderSlice.actions;

export default OrderSlice.reducer;