import { createSlice } from '@reduxjs/toolkit';
const initialState = {

setprice:false,
setcategorie:false,
setgender:false,
setbarnd:false,
setcondition:false,
setlocation:false
};


const DeleteFiltersSlice = createSlice({
  name: 'deletefilters',
  initialState,
  reducers: {


   setCategories: (state, { payload }) => {
    state.setcategorie=payload
   

   },
   setGender: (state, { payload }) => {
    state.setgender=payload

   },
   setBrand: (state, { payload }) => {
    state.setbarnd=payload

   },
   setCondition: (state, { payload }) => {
    state.setcondition=payload

   },
   setPrice: (state, { payload }) => {
    state.setprice=payload

   },
   setLocation: (state, { payload }) => {
    state.setlocation=payload

   },

},
  extraReducers: {

  },
});

export const { setPrice,setCondition, setBrand,setGender,setCategories,setLocation} =
DeleteFiltersSlice.actions;

export default DeleteFiltersSlice.reducer;
