import { combineReducers } from 'redux';
import UserModel from './features/UserModel';
import UserProfile from './features/UserProfile';
import ProfileModel from './features/ProfileModel';
import type_category from './features/Type_categoey/type_category';
import ModelsSilce from './features/Models/ModelsSilce';
import BrandsSlice from './features/Brands/BrandsSlice';
import CategoriesSlice from './features/Categories/CategoriesSlice';
import SubCategoriesSlice from './features/Subcategories/SubCategoriesSlice';
import ImagesSlice from './features/Images/ImagesSlice';
import ProductSlice from './features/Products/ProductSlice'; 
import AddressesSlice from './features/Addresses/AddressesSlice';
import OrderSlice from './features/Order/OrderSlice';
import CurrencySlice from './features/Currency/CurrencySlice';
import FlagsSlice from './features/Flags/FlagsSlice';
import PricesSlice from './features/Prices/PricesSlice';
import RecentlyViewed from './features/RecentlyViewed/RecentlyViewed';
import MybagSlice from './features/Bag/MybagSlice';
import DeleteFiltersSlice from './features/DeleteFilters/DeleteFiltersSlice';
import TopSearchesSlice from './features/TopSearches/TopSearchesSlice';
import LanguageSilce from './features/Languages/LanguageSilce';

export const rootReducer = combineReducers({
    UserModel: UserModel,
    UserProfile:UserProfile,
    ProfileModel:ProfileModel,
    typecategory:type_category,
    allModels:ModelsSilce,
    allBrands:BrandsSlice,
    allCatregories:CategoriesSlice,
    allSubCategories:SubCategoriesSlice,
    allImages:ImagesSlice,
    allproducts:ProductSlice,
    allAddresses:AddressesSlice,
    allOrders:OrderSlice,
    currency:CurrencySlice,
    flags:FlagsSlice,
    allprices:PricesSlice,
    recentlyViewed:RecentlyViewed,
    mybag:MybagSlice,
    deletefilters:DeleteFiltersSlice,
    topsearches:TopSearchesSlice,
    lang:LanguageSilce,
})