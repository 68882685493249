import { createSlice } from '@reduxjs/toolkit';
const initialState = {


idCategory:"",
Type:""
};


const CategoriesSlice = createSlice({
  name: 'allCatregories',
  initialState,
  reducers: {


   getIdCateg: (state, { payload }) => {
    state.idCategory=payload

   },
   SetType: (state, { payload }) => {
    state.Type=payload

   },
 

},
  extraReducers: {

  },
});

export const { getIdCateg,SetType } =
CategoriesSlice.actions;

export default CategoriesSlice.reducer;
