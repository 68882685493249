import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addresses: [],
  usersLocation: [],
  selectedAddress: null,
  adress: "",
};

const AddressesSlice = createSlice({
  name: "allAddresses",
  initialState,
  reducers: {
    GetAllAddresses: (state, { payload }) => {
      state.addresses = payload;
    },

    DeleteAllAddresses: (state, { payload }) => {
      state.addresses = [];
    },

    SetAddresses: (state, { payload }) => {
      state.selectedAddress = payload;
    },
    GetAddress: (state, { payload }) => {
      state.adress = payload;
    },
    SetUsersLocation: (state, { payload }) => {
      //console.log("hiiiiiii",state.userLocations);
      // if (
      //   state.userLocations.filter((address) => {
      //     address.uid = payload.uid;
      //   }).length != 0
      // ){
        //state.usersLocation.push(payload);
      //}
        
    },
  },
  //   extraReducers: {
  //     [getAllddresses.pending]: (state) => {
  //       state.isLoading = true;
  //     },
  //     [getAllddresses.fulfilled]: (state, {payload }) => {

  //       const addresses = payload;

  //       state.isLoading = false;
  //       state.addresses = addresses;
  //       ProductLocalStorage(addresses);
  //     },
  //     [getAllddresses.rejected]: (state, { payload }) => {
  //       state.isLoading = false;

  //     },

  //   },
});

export const { GetAllAddresses, DeleteAllAddresses, SetAddresses, GetAddress, SetUsersLocation } =
  AddressesSlice.actions;

export default AddressesSlice.reducer;
